






















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue'

import {
	// omitEmpties,
	// injectScript,
	// openFilePicker,
	Pagination,
	getRouteMeta,
	convertKeysToSnakeCase,
} from 'vuelpers'

import {
	Id,
	Cart,
	User,
	Address,
	VFormRef,
	CurrentOrder,
	PaypalPayment,
	BackendError,
	Currency,
	DeliveryCharge,
	Config,
	Paypal,
} from '@/types'

import { cartsQuery } from '@/queries'
// import { PAYPAL_SANDBOX } from '@/consts'
import { AuthApi, OrdersApi } from '@/api'
import { getDefaultRoute, parsePaypalConfig } from '@/helpers'
import { mapActions, mapGetters } from 'vuex'
import { Accessors } from 'vue/types/options'
import { getValidators } from '@/mixins/FormMixin'
import { fallbackCurrency, getExchangedValue } from '@/mixins/UseCurrency'
import { fetchProfile, onLoggedIn } from '@/mixins/UseAuth'

import PaypalCheckout from 'vue-paypal-checkout'
import FormAddress from '@/components/Forms/FormAddress.vue'
import CheckoutCartItem from '@/components/CheckoutCartItem.vue'
import DialogPaymentSuccess from '@/components/dialogs/DialogPaymentSuccess.vue'
import CheckoutAddressCard from '@/components/CheckoutAddressCard.vue'
import { MetaInfo } from 'vue-meta'
import { APP_META } from '@/consts'
import FormCoupon from '@/components/Forms/FormCoupon.vue'

export default Vue.extend({
	name: 'Checkout',
	metaInfo(): MetaInfo {
		return {
			...APP_META,
			title: `Checkout | ${APP_META.title}`,
		}
	},
	components: {
		PaypalCheckout,
		FormAddress,
		CheckoutCartItem,
		DialogPaymentSuccess,
		CheckoutAddressCard,
		FormCoupon,
	},
	data: () => ({
		isLoading: false,
		loginError: false,
		paymentSuccess: false,
		isLoadingBank: false,
		paymentError: false,
		dialogBankPayment: false,
		isPaypalConfirming: false,
		isValidDelChargeForm: false,
		errorDeliveryCharge: '',

		rules: getValidators('email', 'required', 'password'),
		paymentTypes: [
			{ name: 'paypal', image: require('@/assets/img/payment-paypal.png') },
			{ name: 'rms', image: require('@/assets/img/rms.png') },
			{ name: 'card', image: require('@/assets/img/bank.png') },
		],
		checkoutMethod: 'register',
		payment: {
			address: '',
			fullName: '',
		},
		form: {
			valid: false,
		},
		guestEmail: '',
		isValidGuestForm: false,
		isCreatingGuestAccount: false,
		credentials: {
			email: '',
			password: '',
		},
		// paypal: {
		// 	production: '',
		// 	sandbox: PAYPAL_SANDBOX,
		// },
		loading: {
			order: false,
		},
		rmsForm: '',
		error: {
			order: [],
		} as BackendError,
	}),
	created() {
		if (!this.$carts.isLoaded && !this.$carts.isLoading) {
			this.getCarts(cartsQuery)
		}
	},
	beforeDestroy() {
		const rxpScript = document.getElementById('rxp')
		if (rxpScript) rxpScript.remove()

		this.$emit('cartDropdown', true)
	},
	watch: {
		currentStep: {
			immediate: true,
			async handler(v: number) {
				if (v > 1) {
					this.onGetDeliveryCharges()
				}

				if (v === 2 && this.addresses?.length) {
					this.setCurrentOrder({
						billingAddress: this.addresses[0],
						shippingAddress: this.addresses[0],
					})
				}

				this.$emit('cartDropdown', v < 6)

				const ref = this.$refs[`step-1`]
				if (!ref) return
				this.$nextTick(() => {
					;(ref as Vue).$el.scrollIntoView({
						behavior: 'smooth',
					})
				})
			},
		},
		$isLoggedIn: {
			immediate: true,
			handler(v: boolean) {
				if (v && this.currentStep === 1) {
					this.setCurrentOrder({
						step: 2,
						completedStep: 1,
					})
				} else if (!v) {
					this.setCurrentOrder({
						step: 1,
						completedStep: 0,
					})
				}
			},
		},
		'$carts.isLoaded': {
			immediate: true,
			handler(): any {
				if (!this.$carts.data.length) {
					return this.$router.push('/cart')
				}
				this.ensureMinimumOrderValue()
			},
		},
		'$cartSummary.orderTotalItem': {
			immediate: true,
			handler(v: number): any {
				if (!this.$carts.isLoaded || v > 0) return
				this.$router.push('/cart')
			},
		},
		$total: {
			immediate: true,
			handler(): any {
				if (!this.$carts.isLoaded) return
				this.ensureMinimumOrderValue()
			},
		},
	},
	computed: {
		...({
			...mapGetters(['$config', '$currentCurrency']),
			...mapGetters('auth', [
				'$currentUser',
				'$isLoggedIn',
				'$isGuestCustomer',
			]),
			...mapGetters('carts', [
				'$total',
				'$carts',
				'$subTotal',
				'$vatAndTax',
				'$currentOrder',
				'$deliveryCharges',
				'$cartSummary',
				'$carriage',
				'$selectedDeliveryCharge',
				'$iSource1Total',
				'$couponDAmount',
			]),
		} as Accessors<{
			$currentUser: User
			$isLoggedIn: boolean
			$carts: Pagination<Cart>
			$currentOrder: CurrentOrder
			$deliveryCharges: Pagination<DeliveryCharge>
			$subTotal: number
			$total: number
			$vatAndTax: number
			$couponDAmount: number
			$isGuestCustomer: boolean
			$cartSummary: any
			$carriage?: number
			$currentCurrency?: Currency
			$selectedDeliveryCharge?: DeliveryCharge
			$config?: Config
			$iSource1Total: number
		}>),
		paypalConfig(): Paypal {
			return parsePaypalConfig(this.$config?.paypal)
		},
		exchangedTotal(): string {
			return getExchangedValue(this.$total).toFixed(2)
		},
		addresses(): Address[] | undefined {
			return this.$currentUser?.customer?.addresses
		},
		deliveryCharges(): DeliveryCharge[] {
			return [...this.$deliveryCharges.data].sort((a, b) => {
				return a.vService.localeCompare(b.vService) || a.dCharge - b.dCharge
			})
		},
		modelBillingAddress: {
			get(): Id | undefined {
				return this.$currentOrder?.billingAddress?.iAddressId
			},
			set(v?: Id) {
				const address = this.addresses?.find((a) => a.iAddressId === v)
				if (address) {
					this.setCurrentOrder({
						billingAddress: address,
					})
				}
			},
		},
		modelShippingAddress: {
			get(): Id | undefined {
				return this.$currentOrder?.shippingAddress?.iAddressId
			},
			set(v?: Id) {
				const address = this.addresses?.find((a) => a.iAddressId === v)
				if (address) {
					this.setCurrentOrder({
						shippingAddress: address,
					})
				}
			},
		},
		vReference: {
			get(): string {
				return this.$currentOrder.vReference || ''
			},
			set(v?: string) {
				this.setCurrentOrder({
					vReference: v,
				})
			},
		},
		deliveryNotes: {
			get(): string {
				return this.$currentOrder.deliveryNotes || ''
			},
			set(v?: string) {
				this.setCurrentOrder({
					deliveryNotes: v,
				})
			},
		},
		currentStepModel: {
			get(): number {
				return this.currentStep - 1
			},
			set(step: number) {
				this.currentStep = step + 1
			},
		},
		currentStep: {
			get(): number {
				return this.$currentOrder.step
			},
			set(step: number) {
				this.setCurrentOrder({ step })
			},
		},
		differentShippingAddress: {
			get(): boolean {
				return this.$currentOrder.differentShippingAddress
			},
			set(v: boolean) {
				this.setCurrentOrder({
					differentShippingAddress: v,
					shippingAddress: v
						? undefined
						: this.$currentOrder.billingAddress,
				})
			},
		},
		deliveryCharge: {
			get(): any {
				return this.$currentOrder.deliveryCharge
			},
			set(v: any) {
				this.setCurrentOrder({
					deliveryCharge: v,
				})
			},
		},
		paymentReference(): string {
			return [
				this.$currentUser.customer?.iCustomerId,
				this.$currentOrder.billingAddress?.iAddressId,
				this.$currentOrder.shippingAddress?.iAddressId,
				this.$currentOrder.deliveryCharge?.iDeliveryChargeId,
				this.$selectedDeliveryCharge?.dCharge || 0,
				this.deliveryNotes,
				this.vReference,
				this.$currentOrder.coupon.vCoupon,
			].join('|_|')
		},
		isDisabledShipping(): boolean {
			return !this.$currentOrder.billingAddress
		},
		isDisabledDeliveryCharge(): boolean {
			return (
				!this.$currentOrder.billingAddress ||
				!this.$currentOrder.shippingAddress
			)
		},
		isDisabledPreview(): boolean {
			return (
				!this.$currentOrder.billingAddress ||
				!this.$currentOrder.shippingAddress ||
				!this.$currentOrder.deliveryCharge
			)
		},
		minOrderValue(): number {
			return getExchangedValue(this.$config?.dMinimumOrderValue)
		},
		canContinue(): boolean {
			return (
				this.$total > 0 &&
				(!this.$iSource1Total || this.$iSource1Total >= this.minOrderValue)
			)
		},
	},
	methods: {
		...mapActions('carts', ['getCarts']),
		...mapActions('auth', ['login', 'setAuthState', 'deleteAddress']),
		...mapActions('carts', [
			'paypalConfirmPayment',
			'setCurrentOrder',
			'resetCurrentOrder',
			'getDeliveryCharges',
			'confirmOrderBank',
		]),
		isSelectedDeliveryCharge(
			deliveryCharge: DeliveryCharge | null,
			iDeliveryChargeId: Id
		) {
			return deliveryCharge?.iDeliveryChargeId === iDeliveryChargeId
		},
		isDisabled(step: number): boolean {
			return (
				this.currentStep !== step && this.$currentOrder.completedStep < step
			)
		},
		async ensureMinimumOrderValue() {
			if (!this.canContinue) {
				await this.$router.push('/cart')
				this.$toast.error(
					`Minimum order amount is ${this.minOrderValue.toFixed(2)}`
				)
				return
			}
		},
		async onGetDeliveryCharges() {
			const { country, vZipCode = '00000' } =
				this.$currentOrder.shippingAddress || {}

			const [err] = await this.getDeliveryCharges({
				destination_zip: vZipCode,
				delivered_country: country?.vCountry || 'United Kingdom',
			})
			if (!err && this.deliveryCharges.length === 1) {
				this.deliveryCharge = this.deliveryCharges[0].iDeliveryChargeId
			}
		},
		onClickPay(type: string) {
			if (type === 'rms') {
				this.onPayWithRms()
			} else if (type === 'card') {
				this.dialogBankPayment = true
			}
		},
		async getRef(refName: string): Promise<any> {
			return new Promise((resolve) => {
				let count = 0
				let intv = setInterval(() => {
					if (this.$refs[refName] || count >= 50) {
						clearInterval(intv)
						resolve(this.$refs[refName])
					}
					count += 1
				}, 50)
			})
		},
		async onChooseCheckoutMethod() {
			if (this.checkoutMethod === 'register') {
				return this.$store.commit('auth/SET', {
					authentication: {
						dialog: true,
						persisted: false,
						currentTab: 'signup',
					},
				})
			}

			const formRef = this.$refs.guestFormRef as VFormRef
			if (!formRef.validate()) return

			this.isCreatingGuestAccount = true
			const [err, res] = await AuthApi.createGuestAccount({
				email: this.guestEmail,
			})
			this.isCreatingGuestAccount = false
			if (err) {
				return this.$toast.error(
					"Can't continue with guest checkout. Please try again later."
				)
			}
			onLoggedIn({
				user: res.user,
				token: res.guestToken,
			})
			this.currentStep += 1
		},
		onDeleteAddress(id: Id) {
			if (confirm('Are you sure you want to delete this address?')) {
				this.deleteAddress(id)
			}
		},
		paymentAuthorized(data: any) {
			console.log('paymentAuthorized', data)
		},
		onShowSuccessDialog(res: any) {
			this.paymentSuccess = true
			setTimeout(async () => {
				this.paymentSuccess = false
				await this.$router.push(
					this.$isGuestCustomer
						? `/orders?order=${res.iSalesOrderId}`
						: `/profile/orders?order=${res.iSalesOrderId}`
				)

				this.resetCurrentOrder()
				this.getCarts(cartsQuery)
			}, 3000)
		},
		async onPayWithRms() {
			const country = this.$currentOrder.billingAddress?.country

			let [err, res] = await OrdersApi.getHpp({
				reference: this.paymentReference,
				countryCode: country?.iNumeric || '826',
				currencyCode: this.$currentCurrency?.code || fallbackCurrency.code,
				billingAddress:
					this.$currentOrder.billingAddress?.vAddress ||
					this.$currentOrder.billingAddress?.vAddress2,
			})
			if (err) {
				this.paymentError = err
				return this.$toast.error(err.message)
			}
			this.rmsForm = res.form
			this.$nextTick(async () => {
				const ref = await this.getRef('rmsFormRef')
				const submitBtn = ref.querySelector('input[type="submit"]')
				submitBtn && submitBtn.click()
			})
		},
		async paymentCompleted(data: PaypalPayment) {
			this.setCurrentOrder({ paypalPaymentRef: data })

			this.isPaypalConfirming = true
			const [err, res] = await this.paypalConfirmPayment(
				convertKeysToSnakeCase({
					paypalResponse: data,
					reference: this.paymentReference,
				})
			)
			this.isPaypalConfirming = false

			if (err) {
				this.paymentError = err
				return console.log(err)
			}
			this.onShowSuccessDialog(res)
		},
		async onBankPlaceOrder() {
			this.isLoadingBank = true
			const [err, res] = await this.confirmOrderBank(
				convertKeysToSnakeCase({
					amount: this.exchangedTotal,
					reference: this.paymentReference,
					currencyCode:
						this.$currentCurrency?.code || fallbackCurrency.code,
				})
			)
			this.isLoadingBank = false

			if (err) {
				this.paymentError = err
				return console.log(err)
			}

			this.dialogBankPayment = false
			this.onShowSuccessDialog(res)
		},
		paymentCancelled(data: any) {
			console.log(data)
			this.paymentError = data
		},
		onBillingAddress(address: Address) {
			this.setCurrentOrder({
				step: 3,
				completedStep: 2,
				billingAddress: address,
				shippingAddress: address,
			} as CurrentOrder)
		},
		onShippingAddress(address: Address) {
			this.setCurrentOrder({
				step: 4,
				completedStep: 3,
				shippingAddress: address,
			} as CurrentOrder)
		},
		onUseSameAddress() {
			this.setCurrentOrder({
				step: 4,
				completedStep: 3,
			} as CurrentOrder)
		},
		onSelectedDeliveryCharge() {
			if (!(this.$refs.step4FormRef as VFormRef).validate()) {
				this.errorDeliveryCharge = 'You must choose the delivery option.'
				return
			}
			this.errorDeliveryCharge = ''
			this.setCurrentOrder({
				step: 5,
				completedStep: 4,
			} as CurrentOrder)
		},
		onPlaceOrder() {
			if (!this.$selectedDeliveryCharge) {
				this.$toast.error('You must choose the delivery option.')
				return this.setCurrentOrder({
					step: 4,
					completedStep: 3,
				})
			}
			this.setCurrentOrder({
				step: 6,
				completedStep: 5,
			})
		},
		async onSubmitSignIn(): Promise<any> {
			if (!(this.$refs.formRef as VFormRef).validate()) return

			this.isLoading = true
			let [err] = await this.login(this.credentials)
			if (err) {
				this.loginError = !!err
				return (this.isLoading = false)
			}

			let [proError] = await fetchProfile()
			this.isLoading = false

			if (!proError) {
				// Ensure role
				let requireRoles = getRouteMeta(this.$route, 'requireRoles')
				if (
					requireRoles &&
					!requireRoles.includes(this.$currentUser.vRole)
				) {
					return this.$router.replace(
						getDefaultRoute(this.$currentUser.vRole)
					)
				}

				this.setAuthState({
					'authentication.dialog': false,
				})

				this.setCurrentOrder({
					step: 2,
					completedStep: 1,
				})
			}

			this.loginError = !!err
		},
	},
})
