



























import Vue from 'vue'

export default Vue.extend({
	name: 'DialogPaymentSuccess',
	props: {
		value: {
			default: false,
			type: Boolean as Vue.PropType<boolean>,
		},
	},
	computed: {
		vModel: {
			get(): boolean {
				return this.value
			},
			set(v: boolean) {
				this.$emit('input', v)
			},
		},
	},
})
