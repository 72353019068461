










































































import Vue from 'vue'
import { Cart } from '@/types'

export default Vue.extend({
	name: 'CheckoutCartItem',
	props: {
		showHeader: {
			default: false,
			type: Boolean as Vue.PropType<boolean>,
		},
		item: {
			required: true,
			type: Object as Vue.PropType<Cart>,
		},
	},
	computed: {
		thumbnail(): string {
			const vImage = this.item?.product?.vImage || ''
			return this.$helper.imgSrc(vImage.split(',')[0])
		},
		dSellPrice(): number {
			return (
				+(this.item?.brand?.dSellPrice || this.item?.product?.dSellPrice) ||
				0
			)
		},
	},
})
